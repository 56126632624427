<template>
    <section id="recent-work-three" class="pt-5">
        <div class=" container-fluid">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section-title-center text-center pb30">
                        
                        <h2>Recently Done Projects</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="recent-wrapper owl-carousel owl-theme">
                <!-- <div class="recent-wrapper"> -->
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-img-three">
                                <img src="@/assets/imgw/recentWork/cv.webp" alt="img">
                                <h3 class="pulse-arae">1</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-work-slider-area ">
                                <div class="left-side-recent">
                                    <h3 class="pt10">
                                        Violence Detection In Servilance Videos Using Deep Networks
                                    </h3>
                                </div>
                                <div class="recent-text-area">
                                    <div class="recent-slider-text">
                                       
                                        
                                        <router-link class="btn btn-theme" to="/project/violence-detection-in-surveillance-video-using-deep-learning">
                                            View Details
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-img-three">
                                <img src="@/assets/imgw/recentWork/cv1.webp" alt="img">
                                <h3 class="pulse-arae">2</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-work-slider-area ">
                                <div class="left-side-recent">
                                    <h3 class="pt10">
                                        2D to 3D image using Machine Learning
                                    </h3>
                                </div>
                                <div class="recent-text-area">
                                    <div class="recent-slider-text">
                                        
                                        
                                        <router-link class="btn btn-theme" to="/project/2d-single-image-to-3d-model">
                                            View Details
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-img-three">
                                <img src="@/assets/imgw/recentWork/dl.webp" alt="img">
                                <h3 class="pulse-arae">2</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-work-slider-area ">
                                <div class="left-side-recent">
                                    <h3 class="pt10">
                                        Face Detection 98% Accuracy
                                    </h3>
                                </div>
                                <div class="recent-text-area">
                                    <div class="recent-slider-text">
                                        <router-link class="btn btn-theme" to="/project/face-detection-on-images-and-real-time-with-deep-learning-technique-mtcnn">
                                            View Details
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-img-three">
                                <img src="@/assets/imgw/recentWork/dl2.webp" alt="img">
                                <h3 class="pulse-arae">2</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-work-slider-area ">
                                <div class="left-side-recent">
                                    <h3 class="pt10">
                                        Vehicle Identification and Classification Through Video Analysis
                                    </h3>
                                </div>
                                <div class="recent-text-area">
                                    <div class="recent-slider-text">


                                        <router-link class="btn btn-theme" to="/project/vehicle-identification-and-classification-through-video-analysis">
                                            View Details
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-img-three">
                                <img src="@/assets/imgw/recentWork/dl3.webp" alt="img">
                                <h3 class="pulse-arae">2</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-work-slider-area ">
                                <div class="left-side-recent">
                                    <h3 class="pt10">
                                        Suspicious Human Activity Recognition.
                                    </h3>
                                </div>
                                <div class="recent-text-area">
                                    <div class="recent-slider-text">
                                        
                                        <router-link class="btn btn-theme" to="/project/suspicious-human-activity-recognition">
                                            View Details
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-img-three">
                                <img src="@/assets/imgw/recentWork/web.webp" alt="img">
                                <h3 class="pulse-arae">2</h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div class="recent-work-slider-area ">
                                <div class="left-side-recent">
                                    <h3 class="pt10">
                                        E-commerce Website.
                                    </h3>
                                </div>
                                <div class="recent-text-area">
                                    <div class="recent-slider-text">
                                        
                                        
                                        <a class="btn btn-theme" href="https://rehahomestore.com/">
                                            Visit Site
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import {onMounted} from 'vue'
    export default {
        setup(){
            onMounted(() => {
                eval('$(\'.recent-wrapper\').owlCarousel({loop: true,dots: false,autoplayHoverPause: true,autoplay: true,smartSpeed: 1000,margin: 0,nav: true,navText: ["<i class=\'flaticon-left\'></i>","<i class=\'flaticon-right\'></i>"],responsive: {0: {items: 1,},768: {items: 1,},1200: {items: 1,}}});')
            })
        }
    }
</script>