<template>
    <section id="blog-area" class="">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section-title-center text-center pb30">
                        
                        <h2>Our Reserch & Solution</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div class="blog-item wow fadeIn " data-wow-duration="1.0s" data-wow-delay=".4s">
                        <div class="blog-img">
                            <a href="https://academic.oup.com/comjnl/advance-article-abstract/doi/10.1093/comjnl/bxaa061/5867750?redirectedFrom=fulltext"><img src="@/assets/imgw/papers/Paper1.webp" alt="img" /></a>
                        </div>
                        <div class="blog-details">

                            <h3>
                                <a href="https://academic.oup.com/comjnl/advance-article-abstract/doi/10.1093/comjnl/bxaa061/5867750?redirectedFrom=fulltext">Fast Learning through Deep Multi-Net CNN</a>
                            </h3>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div class="blog-item wow fadeIn " data-wow-duration="1.0s" data-wow-delay=".4s">
                        <div class="blog-img">
                            <a href="http://pde-gir.com/documents/ViolenceDetectionSurveillanceVideoswithDeepNetwork.pdf"><img src="@/assets/imgw/papers/Paper2.webp" alt="img" /></a>
                        </div>
                        <div class="blog-details">

                            <h3>
                                <a href="https://ieeexplore.ieee.org/abstract/document/8910041">Violence Detection in Surveillance
                                    using Transfer Learning</a>
                            </h3>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div class="blog-item wow fadeIn " data-wow-duration="1.0s" data-wow-delay=".4s">
                        <div class="blog-img">
                            <a href="https://www.computer.org/csdl/proceedings-article/aiccsa/2006/01618503/12OmNwl8GDZ"><img src="@/assets/imgw/papers/Paper3.webp" alt="img" /></a>
                        </div>
                        <div class="blog-details">

                            <h3>
                                <a href="https://www.computer.org/csdl/proceedings-article/aiccsa/2006/01618503/12OmNwl8GDZ">Games over Bluetooth
                                    Publication in ACS/IEEE </a>
                            </h3>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div class="blog-item wow fadeIn " data-wow-duration="1.0s" data-wow-delay=".4s">
                        <div class="blog-img">
                            <a href="https://ieeexplore.ieee.org/abstract/document/8982472/authors#authors"><img src="@/assets/imgw/papers/Paper4.webp" alt="img" /></a>
                        </div>
                        <div class="blog-details">

                            <h3>
                                <a href="https://ieeexplore.ieee.org/abstract/document/8982472/authors#authors">Cluster-based approach for depth estimation
                                    Publication in IEEE</a>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>