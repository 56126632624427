<template>
    <div class="col-lg-8 col-md-12 col-sm-12 col-12">
        <div class="project-desc">
            <div class="project-details-text">
                <div v-if="media" class="embed-responsive embed-responsive-16by9" v-html="media">
                    
                </div>
                <img class="project-img" v-if="image" :src="image" alt="img">

                <br>
                <h3>{{title}}</h3>
                <p class="text-justify">{{description}}</p>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Content',
        props: ['media','title','description','image']
    }
</script>

<style>
    .project-img {
        width: 100%;
        /* height: 400px; */
    }
</style>