<template>
    <div class="app-header">
      <div class="app-header-left">
        <span class="app-icon"></span>
        <router-link class="app-name" to="/">Dashboard</router-link>
      </div>
      <div class="app-header-right">
        
      </div>
    </div>
</template>