<template>
    <br><br>
    <div class="container">
        <div class="row heading">
            <div class="heading-container">
                <h2 class="text-center bottom-line">Meet Our Team</h2>
            </div>
        </div>
        <br>

        <div class="teams-container row team-row owl-carousel owl-theme">
            <div class="team-wrap dev-container">
                <div class="team-member text-center">
                    <div class="team-img">
                        <!-- src/assets/img/team/moin.jpg -->
                        <img src="@/assets/imgw/team/moin.webp" alt="">
                    </div>
                    <h6 class="team-title">Muhammad Moin</h6>
                    <span>Research Analyst</span>
                </div>
            </div>

            <div class="team-wrap dev-container">
                <div class="team-member text-center">
                    <div class="team-img">
                        <img src="@/assets/imgw/team/shifa-2.webp" alt="">
                    </div>
                    <h6 class="team-title">Muhammad Shifa</h6>
                    <span>Data Scientist (ML & CV)</span>
                </div>
            </div>
            <!-- end team member -->

            <div class="team-wrap dev-container">
                <div class="team-member last text-center">
                    <div class="team-img">
                        <img src="@/assets/imgw/team/sharjeel.webp" alt="">
                    </div>
                    <h6 class="team-title">Sharjeel Nawaz</h6>
                    <span>ML & Computer Vision Engineer</span>
                </div>
            </div>
            <div class="team-wrap dev-container">
                <div class="team-member last text-center">
                    <div class="team-img">
                        <img src="@/assets/imgw/team/usama.webp" alt="">
                    </div>
                    <h6 class="team-title">Usama Imdad</h6>
                    <span>Research Engineer (Web & AI)</span>
                </div>
            </div>
            <!-- end team member -->

        </div>
    </div>
</template>

<script>
    import {onMounted} from 'vue'
    export default {
        setup(){
            onMounted(() => {
                eval(`
                    $('.teams-container').owlCarousel({
                        loop: true,
                        dots: false,
                        autoplayHoverPause: false,
                        autoplay: true,
                        smartSpeed: 500,
                        margin: 20,
                        nav: false,
                        responsive: {
                            0: {
                                items: 1,
                            },
                            768: {
                                items: 2,
                            },
                            1200: {
                                items: 3,
                            }
                        }
                    });
                `)
            })
        }
    }
</script>

<style>

.heading-container{
    width: 100%;
}
.our-team .team-row {
    margin-left: -40px;
    margin-right: -40px;
}

.our-team .team-wrap {
    padding: 0 40px;
}

.our-team .container-fluid {
    padding: 0 50px;
}

.team-img img {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    overflow: hidden;
    width: 100%;
}

.team-member,
.team-img {
    position: relative;
    overflow: hidden;
}

.team-title {
    margin: 30px 0 7px;
}

.overlay {
    background-color: rgba(20, 20, 20, .7);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.team-details {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 5%;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.team-details p {
    color: #fff;
}

.team-img:hover .team-details {
    opacity: 1;
    margin-top: -80px;
}

.team-img:hover .overlay {
    opacity: 1;
}

.socials a {
    display: inline-block;
    width: 37px;
    height: 37px;
    background-color: transparent;
}

.socials i {
    line-height: 37px;
    color: #616161;
    font-size: 14px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.team-details .socials i {
    color: #fff;
}

.socials a:hover i {
    color: #fff;
    background-color: #355c7d;
}

</style>