<template>
  <section id="partner" class="bg-color py100">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="logo-area-heading text-center pb30">
                        <h3>Our Partners & Clients</h3>
                    </div>
                    <div class="logo-area owl-carousel owl-theme">
                        <div class="slider-logo">
                            <a href="#!">
                                <img src="@/assets/imgw/partners/asiansol.webp" alt="img" />
                            </a>
                        </div>
                        <div class="slider-logo">
                            <a href="#!">
                                <img src="@/assets/imgw/partners/guardian.webp" alt="img" />
                            </a>
                        </div>
                        <div class="slider-logo">
                            <a href="#!">
                                <img src="@/assets/imgw/partners/paragon.webp" alt="img" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .slider-logo img{
        width: 100px !important;
        height: 100px !important;
    }
</style>

<script>
    import {onMounted} from 'vue'
    export default {
        setup(){
            onMounted(() => {
                eval(`
                    $('.logo-area').owlCarousel({
                        loop: true,
                        dots: false,
                        autoplayHoverPause: true,
                        autoplay: true,
                        smartSpeed: 1000,
                        margin: 20,
                        nav: false,
                        responsive: {
                            0: {
                                items: 3,
                            },
                            768: {
                                items: 4,
                            },
                            1200: {
                                items: 5,
                            }
                        }
                    });
                `)
            })
        }
    }
</script>
