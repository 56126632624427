<template>
    <div class="col-lg-4 col-md-12 col-sm-12 col-12">
        <div class="ps-informations">
            <h3>Project Details</h3>
            <ul>
                <li><span><i class="icofont-check-alt"></i>Category:</span> {{category}}</li>
                <li><span><i class="icofont-check-alt"></i>Completed:</span> {{completed}}</li>
            </ul>
        </div>
        <div class="contact-wedget">
            <h3>Contact Us</h3>
            <ul>
                <li>
                    <i class="icofont-location-pin"></i> {{location}}
                </li>
                <li>
                    <i class="icofont-phone"></i><a href="#!">
                        {{phone}}</a>
                </li>
                <li>
                    <i class="icofont-email"></i> <a href="#!">
                        {{email}}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SideBar',
        props: ['category','completed','location','phone','email']

    }
</script>