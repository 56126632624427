<template>
    <div class="navbar-area">
        <div class="plamb-responsive-nav">
            <div class="container">
                <div class="plamb-responsive-menu">
                    <div class="logo">
                        <router-link to="/">
                            <img src="@/assets/imgw/logo.webp" class="white-logo" alt="logo" style="
                            height: 60px;" />

                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="plamb-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="@/assets/imgw/logo.webp" class="white-logo" alt="logo" />
                        <img src="@/assets/imgw/logo.webp" class="black-logo" alt="logo" />
                    </router-link>
                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item" v-for="nav in navs" :key="nav">
                                <router-link v-if="nav.name !== 'Blog'" class="nav-link" :to="nav.link">
                                    {{nav.name}}
                                    <i v-if="Object.keys(nav).includes('children') && (nav.children.length > 0)" class="fas fa-chevron-down"></i>
                                </router-link>
                                <a v-else class="nav-link" :href="nav.link">
                                    {{nav.name}}
                                    <i v-if="Object.keys(nav).includes('children') && (nav.children.length > 0)" class="fas fa-chevron-down"></i>
                                </a>

                                <span v-if="Object.keys(nav).includes('children') && (nav.children.length > 0)" v-html="genChildNav(nav.children)"></span>
                            </li>
                        </ul>
                        <div class="other-option">
                            <router-link class="btn btn-theme" to="/contact">
                                Contact Us
                            </router-link>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>


<script>
    import {ref} from 'vue'
    import axios from 'axios'

    export default {
        name: 'Header',
        setup(){
            const navs = ref(null)

            navs.value = [

				{
					"name": "Home",
					"link": "/"
				},
				{
					"name": "About",
					"link": "/about"
				},
				{
					"name": "Projects",
					"link": "",
					"children": [
						{
							"name": "Video Suveillance",
							"link": "",
							"children": [
								{
									"name": "Face Recognition Based Attendance System",
									"link":""
								},
								{
									"name": "Hand Detection And Tracking",
									"link":""
								},
								{
									"name": "Face Recognition",
									"link":""
								},
								{
									"name": "Single Shot MultiBox Detector (SSD)",
									"link":""
								},
								{
									"name": "Object Detection",
									"link":""
								},
								{
									"name": "Face Detection",
									"link":""
								},
								{
									"name": "Vehicle Detection",
									"link":""
								},
								{
									"name": "Violence Detection",
									"link":""
								},
								{
									"name": "Human Pose Estimation and Tracking",
									"link":""
								}
							]
						},
						{
							"name": "3D Images/Video",
							"link": "",
							"children": [
								{
									"name": "2D To 3D Image",
									"link": ""
								}
							]
						},
						{
							"name": "Activity Detection",
							"link": "",
							"children": [
								{
									"name": "Human Activity",
									"link": ""
								}
								
							]
						},
						{
							"name": "E-Commerce Site",
							"link": "",
							"children": [
								{
									"name": "T-Shirt",
									"link": ""
								}
							]
						},
						{
							"name": "GAN",
							"link": "",
							"children": [
								{
									"name": "Finger Print",
									"link": ""
								}
							]
						}
					]
				},
				{
					"name": "Product",
					"link": "",
					"children": [
						{
							"name": "Intelligent Invoice Reader",
							"link": ""
						}
					]
				},
				{
					"name": "Blog",
					"link": "https://blogs.slimlogix.com/"
				}
			]


            const genChildNav = (children) => {
                let html = `<ul class="dropdown-menu">`
                for (let child of children){
                    html += '<li class="nav-item">'
                    html += `
                        <a class="nav-link" href="${window.location.origin}/${child.link}">          
                    `
					html += child.name
                    if (Object.keys(child).includes('children') && (child.children.length > 0)){
                        html += '&nbsp<i class="fas fa-chevron-right"></i>'
                    }
					html += '</a>'
                    if (Object.keys(child).includes('children') && (child.children.length > 0)){
                        html += genChildNav(child.children)
                    }
                    html += '</li>'
                }
                html += `</ul>`
                return html
            }
            
            const getNav = () => {
                axios.get(process.env.VUE_APP_BASE_URL+"/nav").then((res) => {
                  navs.value = res.data
                })
            }
            getNav()

            
            return {
                navs, genChildNav
            }
        }
    }
</script>