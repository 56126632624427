<template>
    <section id="work-process-home4" class="pb-5">
        <div class="container">
            <div class="row justify-content-center pb30">
                <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="section-center-two text-center pb30">
                        <h2>Our Work Process</h2>
                       
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="work-process-wrappers">
                        <div class="single-items-process">
                            <div class="work-process-icons rounded">
                                <img src="@/assets/img/svg/analytics.svg" alt="icon">
                                <span class="process-step shadow-sm">1</span>
                            </div>
                            <span class="work-process-divider"></span>
                            <div class="work-process-text">
                                <h4>Data Gathering:</h4>
                                <p>Figuring out what data/features are needed for a specific product.</p>
                            </div>
                        </div>
                        <div class="single-items-process">
                            <div class="work-process-icons rounded">
                                <img src="@/assets/img/svg/neural.svg" alt="icon">
                                <span class="process-step shadow-sm">2</span>
                            </div>
                            <span class="work-process-divider"></span>
                            <div class="work-process-text">
                                <h4>Data Cleaning:</h4>
                                <p>Identify and remove errors & duplicate data to create a reliable dataset.</p>

                            </div>
                        </div>
                        <div class="single-items-process">
                            <div class="work-process-icons rounded">
                                <img src="@/assets/img/svg/data-transfer.svg" alt="icon">
                                <span class="process-step shadow-sm">3</span>
                            </div>
                            <span class="work-process-divider"></span>
                            <div class="work-process-text">
                                <h4>R & D</h4>
                                <p>Integration of machine learning model into existing production environment to make decisions based on data.</p>

                            </div>
                        </div>

                        <div class="single-items-process">
                            <div class="work-process-icons rounded">
                                <img src="@/assets/img/svg/success.svg" alt="icon">
                                <span class="process-step shadow-sm">4</span>
                            </div>
                            <div class="work-process-text">
                                <h4>Model Deployment:</h4>
                                <p>Integration of a machine learning model into an existing production environment to make decisions based on data.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>