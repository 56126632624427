<template>
    <footer id="footer" class="py100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="footer-about-area">
                        <a href="index.html"><img src="@/assets/img/logo.png" alt="img" /></a>
                        <p class="pt30 text-justify">
                            SlimLogix is an AI & Machine-learning technology solutions company that creates and invests in high-end technology products and solutions to solve real-time problems.
                        </p>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                    <div class="footer-list-wedget pl20 single-item-mt-3 responsive-mt-60">
                        <div class="fooote-heading">
                            <h5>Usefull Links</h5>
                        </div>
                        <div class="footer-list pt40">
                            <ul>
                                <li><router-link to="/">Home </router-link></li>
                                <li><router-link to="/about">About us</router-link></li>
                                <li><router-link to="/contact"> Contact us</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                    <div class="footer-list-wedget pl20 single-item-mt-3 responsive-mt-60">
                        <div class="fooote-heading">
                            <h5>Projects</h5>
                        </div>
                        <div class="footer-list pt40">
                            <ul>
                                <li><router-link to="/project/suspicious-human-activity-recognition">Human Activity</router-link></li>
                                <li><router-link to="/project/2d-single-image-to-3d-model">2D to 3D Image</router-link></li>
                                <li><router-link to="/project/latent-fingerprint-enhancement-using-progressive-gan">Finger Print</router-link></li>
                                <li><router-link to="/project/vehicle-identification-and-classification-through-video-analysis">Vehicle Detection</router-link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="footer-list-wedget pl20 responsive-mt-60">
                        <div class="fooote-heading">
                            <h5>Contact Info</h5>
                        </div>
                        <div class="footer-contact-area footer-list pt40">
                            <ul>
                                <li>
                                    <i class="icofont-location-pin"></i> Valencia Town Camercial Market H Block Lahore, Punjab, Pakistan.
                                </li>
                                <li>
                                    <i class="icofont-phone"></i><a href="tel:012-3-456-789">(+92) 3244996300</a>
                                </li>
                                <li>
                                    <i class="icofont-email"></i><a href="malto:info@slimlogix.com">info@slimlogix.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom-img">
            <img src="@/assets/img/svg/footer.svg" alt="img">
        </div>
    </footer>

    <div class="copy-right">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="copy-text">
                        <p>SlimLoigx {{year}}. All Rights Reserved</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="copy-icon text-lg-right">
                        <ul>
                            <li><a href="https://www.facebook.com/SlimLogixOfficial/"><i class="fab fa-facebook-f icon"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/slimlogixofficial/"><i class="fab fa-linkedin-in icon"></i></a></li>
                            <li><a href="malto:info@slimlogix.com"><i class="fas fa-envelope icon"></i></a></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="go-top">
        <i class="fas fa-chevron-up"></i>
        <i class="icofont-long-arrow-up"></i>
    </div>
</template>

<script>
    import {ref} from 'vue'
    export default {
        name: 'Footer',
        setup(){
            const d = new Date()
            const year = ref(d.getFullYear())
            return {
                year
            }
        }
    }
</script>