<template>
    <section id="banner-one">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="home-one-banner-content pt-5">
                        <h1>Artificial Intelligence</h1>
                        <h3>Machine Learning, Deep Learning, Data Sciences, Data Analytics Experts</h3>
                        
                        <p>
                            Providing Scaleable and flexible customized AI & Machine-Learning solutions and Computer-Vision solutions based on latest techniques, technology and tools.
                        </p>
                        <div class="banner-one-btn pt30">
                            <a href="contact.html" class="btn btn-theme">Contact Us</a>

                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="banner-img">
                        <img src="@/assets/imgw/about.webp" class="wow zoomIn" data-wow-duration="2.0s" data-wow-delay=".6s" alt="img" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>