<template>
    <section id="about" class="py100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="left-side-title">
                        <h3>About Company</h3>
                        <h2 class="pt10">
                            We Provide Real Time Data Solutions, Analysis, Analytics And Prediction
                        </h2>
                    </div>
                    <div class="about-detauls pt10">
                        <p>
                            SlimLoigx is expert in providing scaleable and flexible customized AI & Machine-Learning solutions and Computer-Vision solutions based on latest techniques, technology and tools.
                        </p>
                        <p>
                            We help you select the most beneficial plan or service to make your IT headaches our IT solutions. We specialize in AI, Machine-Learning, Data Science and Computer-Vision and we provide real-time problem solutions.
                        </p>
                        <ul class="pt20">
                            <li class="wow zoomIn" data-wow-duration="2.0s" data-wow-delay=".1s">
                                <img src="@/assets/img/about/tick.png" alt="img" />Various Analysis Options
                            </li>
                            <li class="wow zoomIn" data-wow-duration="2.0s" data-wow-delay=".2">
                                <img src="@/assets/img/about/tick.png" alt="img" />Professional Consultancy Service
                            </li>
                            <li class="wow zoomIn" data-wow-duration="2.0s" data-wow-delay=".3s">
                                <img src="@/assets/img/about/tick.png" alt="img" />Advanced Advisory Team
                            </li>
                            <li class="wow zoomIn" data-wow-duration="2.0s" data-wow-delay=".4s">
                                <img src="@/assets/img/about/tick.png" alt="img" />24/7 Support Centers
                            </li>
                        </ul>
                    
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="anitmation-img animation-img-one">
                        <img src="@/assets/imgw/core.webp" alt="img" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>