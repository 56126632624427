<template>
    <section id="service" class="bg-color">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8 col-md-12 col-sm-12 col-12">
                    <div class="section-title-center text-center pb30">
                        <h3 class="pb10">Our Services</h3>
                        <h2>
                            We Offer A Wide Range Of Data Services And Data Analytics Solutions
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 ">
                    <div class="service-box animation-trnslate box-shadow border-radius   wow fadeInUp" data-wow-duration="2.0s" data-wow-delay=".1s">
                        <div class="service-box-inner text-center">
                            <img src="@/assets/imgw/service/1.webp" alt="img" />
                            <h3><a href="#!">Data Science</a></h3>
                            <p>
                                Our data experts providing you best solution utilizing latest techniqes of data engineering.
                            </p>
                            <a href="#!"></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="service-box animation-trnslate box-shadow border-radius  wow fadeInUp" data-wow-duration="2.0s" data-wow-delay=".2s">
                        <div class="service-box-inner text-center">
                            <img src="@/assets/imgw/service/2.webp" alt="img" />
                            <h3><a href="#!">Machine Learning & Deep Learning</a></h3>
                            <p>
                                Our ML services offer advanced deep learning algorithms to solve business & research challenges.
                            </p>
                        
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12 ">
                    <div class="service-box animation-trnslate box-shadow border-radius  wow fadeInUp" data-wow-duration="2.0s" data-wow-delay=".4s">
                        <div class="service-box-inner text-center">
                            <img src="@/assets/imgw/service/3.webp" alt="img" />
                            <h3><a href="#!">Artificial Intelligence</a></h3>
                            <p>
                                Our experienced and Qualified professionals helps you in Providing best AI based Solutions.
                            </p>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="service-box animation-trnslate box-shadow border-radius wow fadeInUp" data-wow-duration="2.0s" data-wow-delay=".6s">
                        <div class="service-box-inner text-center">
                            <img src="@/assets/imgw/service/4.webp" alt="img" />
                            <h3><a href="#!">Computer Vision Solutions</a></h3>
                            <p>
                                Our team develops innovative and custom computer vision modules or artificial intelligences for your modern business.
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="service-box animation-trnslate box-shadow border-radius  wow fadeInUp" data-wow-duration="2.0s" data-wow-delay=".8s">
                        <div class="service-box-inner text-center">
                            <img src="@/assets/imgw/service/5.webp" alt="img" />
                            <h3><a href="#!">Mobile Solutions</a></h3>
                            <p>
                                We develop brilliantly designed and intuitive mobile artificial intelligences that get everyone’s attention.
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div class="service-box animation-trnslate box-shadow border-radius  wow fadeInUp" data-wow-duration="2.0s" data-wow-delay="1s">
                        <div class="service-box-inner text-center">
                            <img src="@/assets/imgw/service/6.webp" alt="img" />
                            <h3><a href="#!">Web Solutions</a></h3>
                            <p>
                                We offer custom Computer Vision artificial intelligence development using secure, robust codebase that provides the seamless user experience.
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>