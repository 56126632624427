<template>
    <section id="testimonial-area" class="py100 bg-color">
        <div class="container">
            <div class="section-title-center text-center pb30">
                <h3 class="pb-5"></h3>
                <h2>Message from CEO</h2>
            </div>
            <div class="row">
                <div class="col-lg-7 offset-lg-5">
                    <div class="clients-review-slides owl-carousel">
                        <div class="review-slide-item">
                            <p class="text-justify">A Data Scientist/ML Engineer/Technical Manager/Architect having 16+ years of professional experience, with proficiency in Computer Vision, Machine Learning, Deep Learning, Artificial Intelligence, Business Application Solutions,
                                Web Solutions and Mobile apps. Research work in Anomaly Detection for Autonomous Video Surveillance. A published author in Suspicious Human Activity Recognition and Fight Detection through video analysis, having strong
                                Management and Communication skills..</p>
                            <div class="copy-icon text-lg-left">
                                <ul>
                                    <li><a href="malto:aqib.mumtaz@slimlogix.com"><i class="fas fa-envelope icon"></i></a></li>
                                    <li><a href="https://stackoverflow.com/users/1996802/aqib-mumtaz"><i class="fab fa-stack-overflow icon"></i></a></li>
                                    <li><a href="skype:aqib.mumtaz?chat"><i class="fab fa-skype icon"></i></a></li>
                                    <li><a href="https://github.com/aqibmumtaz"><i class="fab fa-github icon"></i></a></li>
                                    <li><a href="https://www.linkedin.com/in/aqibmumtaz/"><i class="fab fa-linkedin icon"></i></a></li>




                                </ul>
                            </div>
                            <h6 class="designation pt-4"><span>Aqib Mumtaz -</span> Chief Executive Officer</h6>
                        </div>

                    </div>
                    <!-- clients-review-slides -->
                </div>
            </div>
        </div>
    </section>
    <!-- <section id="testimonial-area" class=" bg-color">
        <div class="container">
            <div class="section-title-center text-center ">
                <h2>Message from CEO</h2>
            </div>
            <div class="item-container">
                <div class="img-container items">
                    <img src="@/assets/img/aqib.jpg" alt="" srcset="">
                    <h6 class="designation pt-4"><span>Aqib Mumtaz -</span> Chief Executive Officer</h6>
                </div>
                <div class="row items">
                    <div class="col-lg-7 offset-lg-5">
                        <p class="text-justify">A Data Scientist/ML Engineer/Technical Manager/Architect having 16+ years of professional experience, with proficiency in Computer Vision, Machine Learning, Deep Learning, Artificial Intelligence, Business Application Solutions,
                            Web Solutions and Mobile apps. Research work in Anomaly Detection for Autonomous Video Surveillance. A published author in Suspicious Human Activity Recognition and Fight Detection through video analysis, having strong
                            Management and Communication skills..</p>
                        <div class="copy-icon text-lg-left">
                            <ul>
                                <li><a href="malto:aqib.mumtaz@slimlogix.com"><i class="fas fa-envelope icon"></i></a></li>
                                <li><a href="https://stackoverflow.com/users/1996802/aqib-mumtaz"><i class="fab fa-stack-overflow icon"></i></a></li>
                                <li><a href="skype:aqib.mumtaz?chat"><i class="fab fa-skype icon"></i></a></li>
                                <li><a href="https://github.com/aqibmumtaz"><i class="fab fa-github icon"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/aqibmumtaz/"><i class="fab fa-linkedin icon"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</template>

<script>
    import {onMounted} from 'vue'
    export default {
        setup(){
            onMounted(() => {
                eval(`
                    jQuery(function ($) {
                        "use strict";

                        function clients_review_slider() {
                            $('.clients-review-slides').owlCarousel({
                                loop: true,
                                margin: 0,
                                dots: true,
                                nav: true,
                                items: 1,
                                mouseDrag: true,
                                touchDrag: true,
                                navText: ["<i class='fa fa-long-arrow-alt-left'></i>", "<i class='fa fa-long-arrow-alt-right'></i>"],
                                autoplay: false,
                                smartSpeed: 1000,
                                autoplayTimeout: 5000,
                            })
                        };
                        clients_review_slider();
                        // wow js
                        $(function () {
                            new WOW().init();
                        });



                        function onHoverthreeDmovement() {
                            var tiltBlock = $('.js-tilt');
                            if(tiltBlock.length) {
                                $('.js-tilt').tilt({
                                    maxTilt: 20,
                                    perspective:700, 
                                    glare: true,
                                    maxGlare: 0
                                })
                            }
                        }
                        jQuery(document).on('ready', function () {
                            (function ($) {
                                // add your functions
                                onHoverthreeDmovement();
                            })(jQuery);
                        });

                    }(jQuery));
                `)
            })
        }
    }
</script>

<style>
    /* @media (min-width: 990px) {
        .item-container{
            display: flex;
        }
        .offset-lg-5{
            margin-left: 0px;
        }
    } */
</style>